import { isServerSide } from 'lib/env'

export const isDoNotTrackEnabled = (): boolean => {
    if (isServerSide) return false

    // @ts-ignore
    const { doNotTrack, navigator } = window

    const dnt =
        // @ts-ignore
        doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack || msTracking()

    if (!dnt) {
        return false
    }

    return (
        dnt === true ||
        dnt === 1 ||
        dnt === 'yes' ||
        (typeof dnt === 'string' && dnt.charAt(0) === '1')
    )
}

function msTracking() {
    if (isServerSide) return false

    const { external } = window
    return (
        typeof external !== 'undefined' &&
        'msTrackingProtectionEnabled' in external &&
        // @ts-ignore
        typeof external.msTrackingProtectionEnabled === 'function' &&
        // @ts-ignore
        window.external.msTrackingProtectionEnabled()
    )
}
