import React, { ReactNode, useEffect, useState } from 'react'
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    BoxProps,
    Button,
    Flex,
    Heading,
    Switch,
    Text,
    chakra,
    useDisclosure,
} from '@chakra-ui/react'
import Link from 'next/link'
import { ArrowLeft } from '@emotion-icons/heroicons-outline/ArrowLeft'
import { routes } from 'routes'
import CookieIconSVG from 'public/img/cookie_icon.svg'

const CookieIcon = chakra(CookieIconSVG)
const ArrowLeftIcon = chakra(ArrowLeft)

const Wrapper = ({
    children,
    ...boxProps
}: {
    children: ReactNode
} & BoxProps) => {
    return (
        <Box
            backgroundColor={'white'}
            position={'fixed'}
            height={'fit-content'}
            width={{ base: '100%', md: '464px' }}
            boxShadow={'cookieConsentBanner'}
            borderRadius={'md'}
            bottom={{ base: '0px', md: '12px' }}
            left={{ base: '0px', md: '12px' }}
            zIndex={'sticky'}
            {...boxProps}
        >
            {children}
        </Box>
    )
}
const CookieBanner = ({
    handleShowSettings,
    handleConsent,
}: {
    handleShowSettings: () => void
    handleConsent: (b: boolean) => void
}) => {
    return (
        <Wrapper p={'32px 36px 30px 36px'}>
            <Flex gridGap="19px" align="center" mb="16px">
                <CookieIcon w="36px" h="36px" />
                <Text variant="bodyTextLarge">We use Cookies</Text>
            </Flex>
            <Text variant="bodyTextSmall" mb={'16px'}>
                {
                    'Cookies help us analyse how our Website is being used, provide you a better experience and serve most relevant content.'
                }
            </Text>
            <Text variant="bodyTextSmall" mb="28px">
                See our{' '}
                <Link href={routes.privacyPolicy()} passHref>
                    <a target="_blank">
                        <u>Privacy Policy</u>
                    </a>
                </Link>{' '}
                for more details.
            </Text>
            <Flex justify="end" gridGap="12px">
                <Button
                    variant="ghost"
                    colorScheme="text"
                    _active={{ backgroundColor: 'none' }}
                    size="sm"
                    onClick={handleShowSettings}
                >
                    Cookie settings
                </Button>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                        handleConsent(true)
                    }}
                >
                    Accept All
                </Button>
            </Flex>
        </Wrapper>
    )
}

const CookieBannerSettings = ({
    handleShowBanner,
    handleConsent,
}: {
    handleShowBanner: () => void
    handleConsent: (b: boolean) => void
}) => {
    const [isEnabledNonEssentialCookies, setIsNonEssentialCookies] = useState(false)

    return (
        <Wrapper pt="22px">
            <Button
                variant="unstyled"
                onClick={handleShowBanner}
                display={'flex'}
                gridGap=" 9px"
                pl="30px"
                mb="16px"
                _focus={{
                    borderColor: 'none',
                }}
                sx={{
                    alignItems: 'center',
                }}
            >
                <ArrowLeftIcon color="text.secondary" boxSize="19px" />
                <Text variant="bodyTextSmall" color="text.secondary">
                    Cookies Manager
                </Text>
            </Button>

            <Box px="36px" mb={'16px'}>
                <Text variant="bodyTextLarge" mb="12px">
                    Cookies Settings
                </Text>
                <Text variant="bodyTextSmall">
                    Cookies necessary for the correct operation of the site are always
                    enabled.
                    <br />
                    Other cookies are configurable.
                </Text>
            </Box>
            <Accordion mb="30px" allowToggle>
                <AccordionItem p="12px 20px 12px 15px">
                    <AccordionButton>
                        <Flex w="100%" justify="space-between" align="center">
                            <Heading size="xs">
                                <AccordionIcon mr="8px" />
                                Essential cookies
                            </Heading>
                            <Text variant="bodyTextSmall" colorScheme="secondary">
                                Always on
                            </Text>
                        </Flex>
                    </AccordionButton>
                    <AccordionPanel>
                        <Text variant="bodyTextSmall">
                            Always On. These cookies are essential so that you can use the
                            website and use its functions. They cannot be turned off.
                            They&apos;re set in response to requests made by you, such as
                            setting your privacy preferences, logging in or filling in
                            forms.
                        </Text>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem p="12px 20px 12px 15px">
                    <Flex w="100%" justify="space-between" align="center">
                        <AccordionButton>
                            <Heading size={'xs'}>
                                <AccordionIcon mr="8px" />
                                Analytics cookies
                            </Heading>
                        </AccordionButton>
                        <Switch
                            isChecked={isEnabledNonEssentialCookies}
                            onChange={() => {
                                setIsNonEssentialCookies(x => !x)
                            }}
                            colorScheme="secondary"
                            size="lg"
                        />
                    </Flex>
                    <AccordionPanel>
                        <Text variant="bodyTextSmall">
                            These cookies collect information to help us understand how
                            our Websites are being used or how effective our marketing
                            campaigns are, or to help us customise our Websites for you.
                            See a list of the analytics cookies we use here.
                        </Text>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <Flex justify="end" px="36px" pb="30px">
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handleConsent(isEnabledNonEssentialCookies)}
                >
                    Save
                </Button>
            </Flex>
        </Wrapper>
    )
}

export const CookieConsentBanner = ({
    handleConsent,
}: {
    handleConsent: (b: boolean) => void
}) => {
    const [showSettings, setShowSettings] = useState(false)
    const { isOpen, onOpen } = useDisclosure()

    useEffect(() => {
        const timeoutId = setTimeout(onOpen, 3000)

        return () => {
            clearTimeout(timeoutId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!isOpen) {
        return null
    }

    if (showSettings) {
        return (
            <CookieBannerSettings
                handleShowBanner={() => setShowSettings(false)}
                handleConsent={handleConsent}
            />
        )
    }

    return (
        <CookieBanner
            handleConsent={handleConsent}
            handleShowSettings={() => setShowSettings(true)}
        />
    )
}
