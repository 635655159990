import React, { ReactNode } from 'react'
import { useRouter } from 'next/router'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { MiddlewareFunction } from '@segment/analytics-next/dist/types/plugins/middleware'
import { getEnvGlobal, getEnvironment } from 'config'
import { routes } from 'routes'
import { isDoNotTrackEnabled } from 'lib/doNotTrack'
import { isEU } from 'lib/isEU'
import {
    cookiesAnalyticsAllTilda,
    cookiesAnalyticsEssentialTilda,
    wasAnalyticsEnabledOnTilda,
} from 'lib/tilda'
import { useCookies } from 'hooks/useCookies'
import useMe from 'hooks/useMe'
import { CookieConsentBanner } from 'components/CookieConsentBanner'

const COOKIE_TRACKING_WORKSHUB = 'wh_tracking_consent'
const SEGMENT_WRITE_KEY = getEnvGlobal('segmentWriteKey')

const AnalyticsContext = React.createContext<{
    analytics?: AnalyticsBrowser
}>(undefined as any)

const middlewareSendToApi: MiddlewareFunction = ({ payload, next }) => {
    fetch(routes.api.analytics(), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload.obj),
    })
    next(payload)
}

export const AnalyticsProvider = ({
    children,
    pageNameForAnalytics,
    pageVersionForAnalytics,
}: {
    pageNameForAnalytics: string
    pageVersionForAnalytics?: string
    children: ReactNode
}) => {
    // introduce this state to prevent mismatch between SSR & CSR version for CookieBanner
    const [consent, setConsent] = React.useState<'required' | 'not-required' | undefined>(
        undefined,
    )
    const { cookies, setCookies } = useCookies()
    const router = useRouter()
    const me = useMe()
    const isLoggedIn = Boolean(me)

    const hasConsentedToBeTracked = Boolean(
        cookies[COOKIE_TRACKING_WORKSHUB] ||
            wasAnalyticsEnabledOnTilda(cookies) ||
            isLoggedIn,
    )

    React.useEffect(() => {
        setConsent(isEU() || isDoNotTrackEnabled() ? 'required' : 'not-required')
    }, [])

    const shouldLoadAnalytics = hasConsentedToBeTracked || consent === 'not-required'
    const shouldSendEventsToMixpanel = Boolean(me)

    const analytics = React.useMemo(() => {
        if (shouldLoadAnalytics) {
            return AnalyticsBrowser.load(
                { writeKey: SEGMENT_WRITE_KEY },
                { integrations: { 'Mixpanel': shouldSendEventsToMixpanel } },
            )
        }
    }, [shouldLoadAnalytics, shouldSendEventsToMixpanel])

    React.useEffect(() => {
        if (me?.id) {
            analytics?.identify(me.id, { name: me.name, type: me.type })
        }
    }, [me?.id, me?.name, me?.type])

    React.useEffect(() => {
        if (analytics && getEnvGlobal('sendAnalyticsEventsToBackend')) {
            analytics.addSourceMiddleware(middlewareSendToApi)
        }
    }, [analytics])

    React.useEffect(() => {
        const trackThePage = () => {
            analytics?.page()
            /* legacy event that we want to support:
               https://github.com/WorksHub/works-hub/blob/master/wh-client/client/src/wh/pages/core.cljs#L129
             */
            analytics?.track(
                `${pageNameForAnalytics} Viewed`,
                pageVersionForAnalytics
                    ? {
                          version: pageVersionForAnalytics,
                      }
                    : undefined,
            )
        }
        // track right after a page is hydrated
        trackThePage()
        router.events.on('routeChangeComplete', trackThePage)
        return () => {
            router.events.off('routeChangeComplete', trackThePage)
        }
    }, [router.events, analytics, pageVersionForAnalytics, pageVersionForAnalytics])

    React.useEffect(() => {
        const trackButtonClick = (evt: MouseEvent) => {
            // @ts-ignore
            const trackId = evt.target?.dataset?.trackId
            if (trackId) {
                analytics?.track('Click Tracked', {
                    trackId,
                })
            }
        }

        window.document.addEventListener('click', trackButtonClick)
        return () => {
            window.document.removeEventListener('click', trackButtonClick)
        }
    }, [analytics])

    const shouldDisplayCookieBanner = consent === 'required' && !hasConsentedToBeTracked

    const handleConsent = (isEnabledNonEssentialCookies: boolean) => {
        setCookies(
            isEnabledNonEssentialCookies
                ? [
                      { name: COOKIE_TRACKING_WORKSHUB, value: true },
                      ...cookiesAnalyticsAllTilda,
                  ]
                : [
                      { name: COOKIE_TRACKING_WORKSHUB, value: true },
                      ...cookiesAnalyticsEssentialTilda,
                  ],
        )
    }

    return (
        <>
            {shouldDisplayCookieBanner && (
                <CookieConsentBanner handleConsent={handleConsent} />
            )}
            <AnalyticsContext.Provider value={{ analytics }}>
                {children}
            </AnalyticsContext.Provider>
        </>
    )
}

export const useAnalytics = () => {
    const result = React.useContext(AnalyticsContext)
    if (!result) {
        throw new Error('Context used outside of its Provider!')
    }
    return result.analytics
}
