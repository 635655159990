import React, { ReactNode } from 'react'
import Cookies, { CookieSetOptions } from 'universal-cookie'

export type CookiesObject = {
    [key: string]: any
}

const CookiesContext = React.createContext<{
    cookies: CookiesObject
    setCookie: Cookies['set']
    setCookies: (
        cookies: { name: string; value: any; options?: CookieSetOptions }[],
    ) => void
}>(undefined as any)

export const CookiesProvider = ({
    cookiesPlainObject,
    children,
}: {
    cookiesPlainObject: CookiesObject
    children: ReactNode
}) => {
    const [cookies, setCookiesState] = React.useState<CookiesObject>(cookiesPlainObject)

    const setCookie: React.ContextType<
        typeof CookiesContext
    >['setCookie'] = React.useCallback((...args) => {
        setCookiesState(cookies => {
            const newCookie = new Cookies(cookies)
            newCookie.set(...args)
            return newCookie.getAll()
        })
    }, [])

    const setCookies: React.ContextType<
        typeof CookiesContext
    >['setCookies'] = React.useCallback(newCookies => {
        setCookiesState(cookies => {
            const newCookie = new Cookies(cookies)
            newCookies.forEach(c => newCookie.set(c.name, c.value, c.options))
            return newCookie.getAll()
        })
    }, [])

    return (
        <CookiesContext.Provider value={{ cookies, setCookie, setCookies }}>
            {children}
        </CookiesContext.Provider>
    )
}

export const useCookies = () => {
    const result = React.useContext(CookiesContext)
    if (!result) {
        throw new Error('CookiesContext used outside of its Provider!')
    }
    return result
}
