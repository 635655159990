import { CookiesObject } from 'hooks/useCookies'

const COOKIE_TRACKING_TILDA = 't_cookiesConsentGiven'
const COOKIE_TRACKING_TILDA_CATEGORIES = 't_cookiesCategories'
const TILDA_CATEGORY_ANALYTICS = 'analytics'

export const cookiesAnalyticsEssentialTilda = [
    { name: COOKIE_TRACKING_TILDA, value: true },
]

export const cookiesAnalyticsAllTilda = [
    ...cookiesAnalyticsEssentialTilda,
    {
        name: COOKIE_TRACKING_TILDA_CATEGORIES,
        value: [TILDA_CATEGORY_ANALYTICS],
    },
]

export const wasAnalyticsEnabledOnTilda = (cookies: CookiesObject) => {
    return (
        cookies[COOKIE_TRACKING_TILDA] &&
        cookies[COOKIE_TRACKING_TILDA_CATEGORIES].include(TILDA_CATEGORY_ANALYTICS)
    )
}
